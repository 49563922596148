.sidebarMenuItem {
    color: #FFFFFF;
    font-weight: normal;
    transition: color 0.3s, font-size 0.3s, font-weight 0.3s, background-color 0.3s;
  }
  
  .sidebarMenuItem:hover {
    color: #FF6600;
    font-size: 1.1em;
    font-weight: bold;
    background-color: #333333;
  }
  